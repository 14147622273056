import React, { useEffect, useRef, useState } from "react";
import "./dashboard.css";
import { useNavigate } from "react-router-dom";
import style from "../deliverability/deliverabilityTable.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Input,
  Row,
  Switch,
  Table,
  Dropdown,
  Spin,
  Pagination,
  notification,
  Popover,
  message,
} from "antd";
import { useLocation, useParams } from "react-router";
import {
  connectedEmailList,
  getDeliverabilityAnalyticsData,
  getEmailAnalyticsData,
  getEmailDataById,
} from "../../../redux/dashboard/action";
import {
  getItem,
  removeItem,
  setItem,
} from "../../../utility/localStorageControl";
import {
  capitalizeFirstAndWordLetter,
  capitalizeFirstLetter,
} from "../../../config/CommonFuntion";
import WarmUpSetting from "../../../component/WarmUpSetting/WarmUpSetting";
import img from "../../../assets/signup/unsuccessful-search.png";
import WarmupConfirm from "../../../component/dashboardModal/WarmupConfirmModal/WarmupConfirm";
import ActiveEmailModal from "../../../component/dashboardModal/ActiveEmailModal/ActiveEmailModal";
import DeleteEmailModal from "../../../component/dashboardModal/DeleteEmailModal/DeleteEmailModal";
import DeleteIcon from "../../../assets/dashboard/trash.png";
import ReportIcon from "../../../assets/dashboard/document-text.png";
import settingIcon from "../../../assets/dashboard/setting-2.png";
import rightIcon from "../../../assets/Email/notification-right.png";
import connectEmailICon from "../../../assets/dashboard/connectEmail.png";
import disConnectEmailICon from "../../../assets/dashboard/disConnectEmail.png";
import {
  EmailActiveWarmupOn,
  EmailConnectDelete,
  EmailDisconnect,
  updateAgainMessageShow,
} from "../../../state/EmailConnect/Emailconnect.api";
import GoogleSVGIcon from "../../../assets/dashboard/GoogleSVGIcon";
import SMTPSVGIcon from "../../../assets/dashboard/SMTPSVGIcon";
import MicrisoftSVGIcon from "../../../assets/dashboard/MicrisoftSVGIcon";
import SuccessSVGIcon from "../../../assets/dashboard/SuccessSVGIcon";
import FailSVGIcon from "../../../assets/dashboard/FailSVGIcon";
import viewSettingIcons from "../../../assets/dashboard/eye.png";
import { warmupAccountSetting } from "../../../redux/connect-account/action";
import DisconnectEmailModal from "../../../component/dashboardModal/DisconnectEmailModal/DeleteEmailModal";
import ReactSpeedometer from "react-d3-speedometer";
import { useMicrosoftConnect } from "../../../state/EmailConnect/Emailconnect.hook";

// DEFINE MAIN FUNCTION
const Dashboard = () => {
  // DEFINE ALL LISTS WITH REDUX
  let listOfEmail = useSelector((state) => state.dashboard.emailList);
  let countsOfEmail = useSelector((state) => state.dashboard.emailListCounts);
  let detailsOfEmailAnalytics = useSelector((state) => state.dashboard);

  let detailsOfEmailTypeAnalyticsData = useSelector(
    (state) => state.dashboard.typeData
  );

  // DEFINE ALL STATES
  let isMount = useRef(true);
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let userDetails = useSelector((state) => {
    if (state?.userDetails && Object.keys(state.userDetails).length) {
      return state.userDetails;
    } else if (getItem("userDetails")) {
      return getItem("userDetails");
    } else {
      return {};
    }
  });
  const [pageSize, setPageSize] = useState(10);
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [tableLoading, setTableLoading] = useState(true);
  const [listLoading, setListLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedValue, setSelectedValue] = useState({});
  const [warmupModal, setWarmupModal] = useState(false);
  const [activeEmailModal, setActiveEmailModal] = useState(false);
  const [deleteEmailModal, setDeleteEmailModal] = useState(false);
  const [disconnectEmailModal, setDisconnectEmailModal] = useState(false);
  const [activeModalLoading, setActiveModalLoading] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [WarmupConfirmModal, setWarmupConfirModal] = useState(false);
  const [editWarmupIndex, setEditWarmupIndex] = useState("1");
  const [settingModelClick, setSettingModelClick] = useState(false);
  const [isLoadingEditEmail, setIsLoadingEditEmail] = useState(false);
  const [disconnectEmailModalLoading, setDisconnectEmailModalLoading] =
    useState(false);

  console.log("editWarmupIndex", editWarmupIndex);
  console.log("pageSize", pageSize);
  const [tableSortingValues, setTableSortingValues] = useState({
    warmup_status: "",
    warmup_mode: "",
    sent_today: "",
    receive_today: "",
    deliverability: "",
  });
  const [openDrawer, setOpenDrawer] = useState(true);
  console.log("settingModelClick", settingModelClick);
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    if (isMount.current) {
      list(currentPage, pageSize, searchData, "", "", "", "", "", true);
      isMount.current = false;
    } else {
      const delayDebounceFn = setTimeout(() => {
        list(1, 10, searchData, "", "", "", "", "", false);
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchData]);

  // DEFINE FUNCTION FOR GANDEL CLICK OUTSIDE OF TABLE
  const handleClickOutside = (event) => {
    if (tableRef.current && !tableRef.current.contains(event.target)) {
      setSelectedRow([]);
    }
  };
  console.log("oepnDraswer", openDrawer);
  // DEFINE USEEFFECT FOR CHECK COMPONENT MOUNTS
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const capitalizeFirstLetter = (string, status, planValidity) => {
    return `${string?.charAt(0).toUpperCase() + string?.slice(1)} ${
      planValidity ? `(${planValidity})` : ""
    } ${status ? `(${status})` : ""}`;
  };
  useEffect(() => {
    if (window.gist && typeof window.gist.identify === "function") {
      const planType =
        userDetails?.subscriptions?.subscriptionPlan?.subscription_plan_type;
      const planValidity =
        userDetails?.subscriptions?.subscriptionPlan
          ?.subscription_plan_validity;
      window.gist.identify(userDetails?.id, {
        email: userDetails?.email,
        name: `${userDetails?.first_name} ${userDetails?.last_name}`,
        current_plan:
          userDetails?.free_trial && !userDetails?.subscribed
            ? "Free Plan"
            : !userDetails?.free_trial && !userDetails?.subscribed
            ? " Plan expired"
            : userDetails?.subscriptions?.subscriptionPlan
                ?.subscription_plan_type
            ? capitalizeFirstLetter(
                planType,
                userDetails?.subscriptions?.subscription_type,
                planValidity
              )
            : "-",
      });
    } else {
      console.error("Gist script is not loaded yet.");
    }
  }, []);

  // DEFINE FUNCTION FOR CONNECTED EMAIL LIST
  const list = async (
    page,
    current,
    search,
    warmup_status,
    warmup_mode,
    sent_today,
    receive_today,
    deliverability,
    loaderStatus
  ) => {
    if (loaderStatus) {
      setListLoading(true);
    }
    setTableLoading(true);
    let queryStrings = {
      page: page,
      limit: current,
      search: search,
      warmup_status: warmup_status,
      warmup_mode: warmup_mode,
      sent_today: sent_today,
      receive_today: receive_today,
      deliverability: deliverability,
      pagination: true,
    };

    const response = await dispatch(connectedEmailList(queryStrings));
    if (response.status) {
      if (loaderStatus) {
        setListLoading(false);
      }
      setTableLoading(false);
    } else {
      if (loaderStatus) {
        setListLoading(false);
      }
      setTableLoading(false);
    }
  };
  const dashboardData = useSelector((state) => state.dashboard.emailList);
  console.log("dashboardData", dashboardData?.length);
  // DEFINE FUNCTION FOR ACTIVE EMAIL API CALL
  const ActiveEmailWarmup = async (id, status, againShow) => {
    setActiveModalLoading(true);
    if (againShow) {
      let payload = {
        message_dont_show: againShow,
      };
      const response = await dispatch(updateAgainMessageShow(payload));
      if (response) {
        setItem("userDetails", {
          ...getItem("userDetails"),
          message_dont_show: true,
        });
      }
    }
    const response = await dispatch(EmailActiveWarmupOn(id, status));
    if (response) {
      setWarmupConfirModal(false);
      setActiveEmailModal(false);
      setSelectedValue({});
      list(
        currentPage,
        pageSize,
        searchData,
        "",
        "",
        tableSortingValues.sent_today,
        tableSortingValues.receive_today,
        "",
        false
      );
      setActiveModalLoading(false);
    } else {
      setActiveModalLoading(false);
    }
  };

  // DEFINE FUNCTION FOR DELETE EMAIL API CALL
  const DeleteEmailWarmup = async (id) => {
    setDeleteModalLoading(true);
    const response = await dispatch(EmailConnectDelete(id));
    if (response) {
      setDeleteModalLoading(false);
      setDeleteEmailModal(false);
      setSelectedValue({});
      notification.success({
        message: (
          <span className="notificationText">
            Email has been deleted successfully
          </span>
        ),
        description: <span className="startBorder"></span>,
        icon: <img src={rightIcon} alt="right-icon" width={20} height={20} />,
        className: "custom-notification",
        duration: 3,
        style: { width: "362px", height: "68px" },
      });
      list(currentPage, pageSize, searchData, "", "", "", "", "", false);
    } else {
      setDeleteModalLoading(false);
    }
  };

  // DEFINE FUNCTION FOR DELETE EMAIL API CALL
  const disconnectEmailWarmup = async (id) => {
    setDisconnectEmailModalLoading(true);
    const response = await dispatch(EmailDisconnect(id));
    if (response) {
      setDisconnectEmailModalLoading(false);
      setDisconnectEmailModal(false);
      setSelectedValue({});
      notification.success({
        message: (
          <span className="notificationText">
            Email has been disconnect successfully
          </span>
        ),
        description: <span className="startBorder"></span>,
        icon: <img src={rightIcon} alt="right-icon" width={20} height={20} />,
        className: "custom-notification",
        duration: 3,
        style: { width: "362px", height: "68px" },
      });
      list(currentPage, pageSize, searchData, "", "", "", "", "", false);
    } else {
      setDisconnectEmailModalLoading(false);
    }
  };
  const navigateToMicroSoft = (data) => {
    window.location.href = data.url;
  };
  const { mutate: connectMicroSoft } = useMicrosoftConnect(navigateToMicroSoft);
  // DEFINE FUNCTION FOR TABLE PAGE CHANGE
  const onPageChange = async (page, current) => {
    setCurrentPage(page);
    setPageSize(current);
    list(
      page,
      current,
      searchData,
      tableSortingValues.warmup_status,
      tableSortingValues.warmup_mode,
      tableSortingValues.sent_today,
      tableSortingValues.receive_today,
      tableSortingValues.deliverability,
      false
    );
  };

  // DEFINE FUNCTION FOR TABLE PAGE CHANGE
  const onEditEmail = async (value, type, status, esp_specific) => {
    console.log("status", status);
    setWarmupModal(true);
    setIsLoadingEditEmail(true);
    setSelectedValue(value);
    setActiveModalLoading(true);
    if (status == "activity") {
      const response = await dispatch(getEmailAnalyticsData(value?.id, type));
      if (response) {
        setActiveModalLoading(false);
      } else {
        setActiveModalLoading(false);
      }
    }
    if (status == "deliverability") {
      const response = await dispatch(
        getDeliverabilityAnalyticsData(value?.id, type, esp_specific)
      );
      if (response) {
        setActiveModalLoading(false);
      } else {
        setActiveModalLoading(false);
      }
    }
    setIsLoadingEditEmail(false);
  };
  const warmupSettingData = useSelector((state) => state);
  const fetchWarmupSetting = async (id) => {
    try {
      await dispatch(warmupAccountSetting(id));
    } catch (error) {
      console.log("error ", error);
    }
  };
  // const history = useHistory();
  const location = useLocation();

  const onChange = (event) => {
    const { name, value } = event?.target;
    const params = new URLSearchParams({ [name]: value });
    // history.replace({ pathname: location.pathname, search: params.toString() });
  };
  const path = useLocation();
  const { id, type } = useParams();
  console.log("id", id, type);
  const getWarmupSettingDataById = async (id) => {
    if (id) {
      const res = await dispatch(getEmailDataById(id));
      console.log("res", res);
      if (res) {
        setSelectedValue(res);
      }
    }
  };

  useEffect(() => {
    const isModelOpen = path.pathname.includes("/report");
    const isOpenSetting = path.pathname.includes("/setting");
    if (isModelOpen && dashboardData) {
      setSettingModelClick(true);
      setWarmupModal(true); // Assuming this comes from useState or similar
      setOpenDrawer(true); // Assuming this comes from useState or similar
      setEditWarmupIndex("1");
      const userId = parseInt(path.pathname.split("/")[2]);
      getWarmupSettingDataById(userId);
    } else if (isOpenSetting && dashboardData) {
      setSettingModelClick(true);
      setWarmupModal(true); // Assuming this comes from useState or similar
      setOpenDrawer(true); // Assuming this comes from useState or similar
      setEditWarmupIndex("2");
      const userId = parseInt(path.pathname.split("/")[2]);
      getWarmupSettingDataById(userId);
    } else {
      setSettingModelClick(false);
      setWarmupModal(false); // Assuming this comes from useState or similar
      setOpenDrawer(false); // Assuming this comes from useState or similar
      setEditWarmupIndex("1");
    }
  }, [path, dashboardData]);
  const NeddleWidth = () => {
    if (dashboardData?.length > 0) {
      for (let i = 0; i < 40; i++) {
        if (dashboardData.length > 0) {
          const speedometerNedle = document.getElementsByClassName("pointer");

          // Check if speedometerNedle[speedometerNedleIndex] exists and is an element
          if (
            speedometerNedle.length > i &&
            speedometerNedle[i]?.querySelector
          ) {
            const pathElement = speedometerNedle[i].querySelector("path");

            // Check if pathElement is valid and has setAttribute method
            if (pathElement && pathElement.setAttribute) {
              const newDAttributeValue =
                "M 2.5 0 C 1.667 -12.5 0.833 -25 0 -25 C -0.833 -25 -1.667 0 -2.5 0 C -1.667 0 -0.833 2.5 0 2.5 C 0.833 2.5 1.667 1.25 2.5 0";
              pathElement.setAttribute("d", newDAttributeValue);
              console.log("Updated path d attribute:", newDAttributeValue);
            } else {
              console.error(
                "pathElement or its setAttribute method is not valid."
              );
            }
          } else {
            console.error(
              `speedometerNedle[${i}] or its querySelector method is not valid.`
            );
          }
        } else {
          console.log("No users in the list.");
        }
      }
    }
  };

  useEffect(() => {
    setTableLoading(true);
    // setListLoading(true);
    setTimeout(() => {
      NeddleWidth();
    }, 300);

    // setTableLoading(false);
    // setListLoading(false);
  }, [dashboardData, tableSortingValues]);

  // DEFINE TABLE COLUMNS
  const columns = [
    {
      dataIndex: "column1",
      key: "column1",
      className: "custom-header",
      onCell: (record, rowIndex) => {
        return {
          onClick: (e) => {
            e.stopPropagation();
          },
        };
      },
      render: (text, item) => (
        <Switch
          onChange={(value) => {
            if (value == true) {
              if (userDetails?.message_dont_show == false) {
                setActiveEmailModal(true);
                setSelectedValue(item);
              } else {
                setActiveEmailModal(false);
                setSelectedValue(item);
                ActiveEmailWarmup(item?.id, true, false);
              }
              setSelectedValue(item);
              setWarmupConfirModal(false);
            } else {
              setWarmupConfirModal(true);
              setSelectedValue(item);
              setActiveEmailModal(false);
            }
          }}
          value={item?.warmup_status == "active" ? true : false}
          style={{
            backgroundColor: item?.warmup_status == "active" ? "#F2F4F7" : "",
          }}
        />
      ),
    },
    {
      title: <span>NAME</span>,
      dataIndex: "NAME",
      key: "column2",
      className: "custom-header",
      render: (icon, value) => {
        return (
          <span className="columEmailData">
            <label>
              {capitalizeFirstAndWordLetter(
                value?.sender_username == ""
                  ? value?.sender_email
                  : value?.sender_username
              )}
            </label>
            <div>
              <span>
                {value?.sender_email}{" "}
                {value?.warmup_status == "active" ||
                value?.warmup_status == "paused" ? (
                  <div className="success_email_popover">
                    <SuccessSVGIcon />
                  </div>
                ) : (
                  <Popover
                    overlayClassName="faild_email_popover"
                    title={
                      value?.mail_failure_reason
                        ? value?.mail_failure_reason
                        : ""
                    }
                  >
                    <FailSVGIcon />{" "}
                  </Popover>
                )}{" "}
              </span>
              <span>
                {value?.sender_username}{" "}
                {value.type == "google" ? (
                  <GoogleSVGIcon />
                ) : value.type == "smtp_imap" ? (
                  <SMTPSVGIcon />
                ) : (
                  <MicrisoftSVGIcon />
                )}
              </span>
            </div>
          </span>
        );
      },
    },
    {
      title: (
        <span
          onClick={() => {
            setTableSortingValues({
              ...tableSortingValues,
              warmup_status:
                tableSortingValues.warmup_status == "" ||
                tableSortingValues.warmup_status == "DESC"
                  ? "ASC"
                  : "DESC",
            });
            list(
              currentPage,
              pageSize,
              searchData,
              tableSortingValues.warmup_status == "" ||
                tableSortingValues.warmup_status == "DESC"
                ? "ASC"
                : "DESC",
              "",
              "",
              "",
              "",
              false
            );
          }}
        >
          WARM-UP STATUS{" "}
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.0835 8.74967L7.00016 11.6663L9.91683 8.74967M4.0835 5.24967L7.00016 2.33301L9.91683 5.24967"
              stroke="#98A2B3"
              strokeWidth="1.16667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      ),
      dataIndex: "warmup_status",
      key: "warmup_status",
      className: "custom-header",
      render: (icon, value) => {
        return (
          <span
            className="columStatusData"
            style={{
              backgroundColor:
                value?.warmup_status == "active"
                  ? "#D7FFDF"
                  : value?.warmup_status == "paused"
                  ? "#FCF3C9"
                  : "#FFE1E1",
              color:
                value?.warmup_status == "active"
                  ? "#00B926"
                  : value?.warmup_status == "paused"
                  ? "#D37C0C"
                  : "#E31F1F",
            }}
          >
            {capitalizeFirstLetter(value?.warmup_status)}
          </span>
        );
      },
    },
    {
      title: (
        <span
          onClick={() => {
            setTableSortingValues({
              ...tableSortingValues,
              warmup_mode:
                tableSortingValues.warmup_mode == "" ||
                tableSortingValues.warmup_mode == "DESC"
                  ? "ASC"
                  : "DESC",
            });
            list(
              currentPage,
              pageSize,
              searchData,
              "",
              tableSortingValues.warmup_mode == "" ||
                tableSortingValues.warmup_mode == "DESC"
                ? "ASC"
                : "DESC",
              "",
              "",
              "",
              false
            );
          }}
        >
          WARM UP MODE{" "}
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.0835 8.74967L7.00016 11.6663L9.91683 8.74967M4.0835 5.24967L7.00016 2.33301L9.91683 5.24967"
              stroke="#98A2B3"
              strokeWidth="1.16667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      ),
      dataIndex: "warmup_mode",
      key: "warmup_mode",
      className: "custom-header",
      render: (icon, value) => {
        return (
          <span className="columData">
            {capitalizeFirstLetter(value?.warmup_mode)}
          </span>
        );
      },
    },
    {
      title: (
        <span
          onClick={() => {
            setTableSortingValues({
              ...tableSortingValues,
              sent_today:
                tableSortingValues.sent_today == "" ||
                tableSortingValues.sent_today == "DESC"
                  ? "ASC"
                  : "DESC",
            });
            list(
              currentPage,
              pageSize,
              searchData,
              "",
              "",
              tableSortingValues.sent_today == "" ||
                tableSortingValues.sent_today == "DESC"
                ? "ASC"
                : "DESC",
              "",
              "",
              false
            );
          }}
        >
          SENT TODAY{" "}
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.0835 8.74967L7.00016 11.6663L9.91683 8.74967M4.0835 5.24967L7.00016 2.33301L9.91683 5.24967"
              stroke="#98A2B3"
              strokeWidth="1.16667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      ),
      dataIndex: "sent_today",
      key: "sent_today",
      className: "custom-header",
      render: (icon, value) => {
        return <span className="columData">{value?.sent_today}</span>;
      },
    },
    {
      title: (
        <span
          onClick={() => {
            setTableSortingValues({
              ...tableSortingValues,
              receive_today:
                tableSortingValues.receive_today == "" ||
                tableSortingValues.receive_today == "DESC"
                  ? "ASC"
                  : "DESC",
            });
            list(
              currentPage,
              pageSize,
              searchData,
              "",
              "",
              "",
              tableSortingValues.receive_today == "" ||
                tableSortingValues.receive_today == "DESC"
                ? "ASC"
                : "DESC",
              "",
              false
            );
          }}
        >
          RECEIVED TODAY{" "}
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.0835 8.74967L7.00016 11.6663L9.91683 8.74967M4.0835 5.24967L7.00016 2.33301L9.91683 5.24967"
              stroke="#98A2B3"
              strokeWidth="1.16667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      ),
      dataIndex: "receive_today",
      key: "receive_today",
      className: "custom-header",
      render: (icon, value) => {
        return <span className="columData">{value?.receive_today}</span>;
      },
    },
    {
      title: (
        <span
          onClick={() => {
            setEditWarmupIndex("1");
            setTableSortingValues({
              ...tableSortingValues,
              deliverability:
                tableSortingValues.deliverability == "" ||
                tableSortingValues.deliverability == "DESC"
                  ? "ASC"
                  : "DESC",
            });
            list(
              currentPage,
              pageSize,
              searchData,
              "",
              "",
              "",
              "",
              tableSortingValues.deliverability == "" ||
                tableSortingValues.deliverability == "DESC"
                ? "ASC"
                : "DESC",
              false
            );
          }}
        >
          DELIVERABILITY{" "}
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.0835 8.74967L7.00016 11.6663L9.91683 8.74967M4.0835 5.24967L7.00016 2.33301L9.91683 5.24967"
              stroke="#98A2B3"
              strokeWidth="1.16667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      ),
      dataIndex: "deliverability",
      key: "deliverability",
      className: "custom-header",
      render: (icon, value) => {
        return (
          <div className="deliverabilityMeterContainer deliverabilityData">
            <div style={{ height: "60px" }}>
              <ReactSpeedometer
                maxSegmentLabels={3}
                segments={3}
                maxValue={100}
                width={100}
                ringWidth={6}
                // height={45}
                currentValueText=""
                value={value?.deliverability ? value?.deliverability : 0}
                needleColor="#272525"
                needleHeightRatio={0.5}
                paddingHorizontal={0}
                paddingVertical={0}
                labelFontSize={0}
                valueTextFontSize={0}
                segmentColors={["#DA6868", "#E3D02B", "#5BCD7C"]}
              />
            </div>
            <div className="part"></div>
            <span
              style={{
                color:
                  value?.deliverability < 33
                    ? "#E31F1F"
                    : value?.deliverability < 66
                    ? "#D37C0C"
                    : "Green",
              }}
            >
              {value?.deliverability}%
            </span>
          </div>
        );
      },
    },
    {
      dataIndex: "column1",
      key: "more",
      className: "custom-header",
      onCell: (record, rowIndex) => {
        return {
          onClick: (e) => {
            e.stopPropagation();
          },
        };
      },
      render: (icon, value) => {
        console.log("value", value);
        return (
          <span>
            <Dropdown
              // trigger={"click"}
              overlayClassName="customTableDropDown"
              className="customDropDown"
              menu={{
                items: [
                  value?.warmup_status == "active" ||
                  value?.warmup_status == "paused"
                    ? {
                        key: "0",
                        label: (
                          <div className="dropdownText">
                            <p>Disconnect Email</p>
                          </div>
                        ),
                        icon: <img src={disConnectEmailICon} alt="setting" />,
                        onClick: () => {
                          console.log("disconnected mail");
                          // setWarmupConfirModal(true);
                          setDisconnectEmailModal(true);
                          setSelectedValue(value);
                          // setActiveEmailModal(false);
                          // console.log("responsie", res);
                          // message.success("Sucessfully disconnected ");
                        },
                      }
                    : {
                        key: "0",
                        label: (
                          <div className="dropdownText">
                            <p>Connnect Email</p>
                          </div>
                        ),
                        icon: <img src={connectEmailICon} alt="setting" />,
                        onClick: (item) => {
                          fetchWarmupSetting(value.id);
                          if (value.type === "google") {
                            navigate(`/new-email/google/${value.id}`);
                          }
                          if (value.type === "smtp_imap") {
                            navigate(`/new-email/smtp/${value.id}`);
                          }
                          if (value.type === "microsoft") {
                            connectMicroSoft();
                          }
                        },
                      },
                  {
                    key: "1",
                    label: (
                      <div className="dropdownText">
                        <p
                          onClick={() => {
                            setSettingModelClick(true);
                            setEditWarmupIndex("2");
                          }}
                        >
                          Warmup Settings
                        </p>
                      </div>
                    ),
                    icon: <img src={settingIcon} alt="setting" />,
                    onClick: () => {
                      onEditEmail(value, "activity", "today");
                      setEditWarmupIndex("2");
                      navigate(`/email-warmup/${value?.id}/setting`);
                      setOpenDrawer(true);
                      setSettingModelClick(true);
                    },
                  },
                  value.type !== "microsoft" && {
                    key: "2",
                    label: (
                      <div className="dropdownText">
                        <p>View Connection Settings</p>
                      </div>
                    ),
                    icon: <img src={viewSettingIcons} alt="setting" />,
                    onClick: () => {
                      fetchWarmupSetting(value.id);
                      if (value.type === "google") {
                        navigate(`/new-email/google/${value.id}`);
                      }
                      if (value.type === "smtp_imap") {
                        navigate(`/new-email/smtp/${value.id}`);
                      }
                    },
                  },
                  {
                    key: "3",
                    label: (
                      <div className="dropdownText">
                        <p onClick={() => setEditWarmupIndex("1")}>
                          Warmup Report
                        </p>
                      </div>
                    ),
                    icon: <img src={ReportIcon} alt="setting" />,
                    onClick: () => {
                      onEditEmail(value, "activity", "today");
                      setEditWarmupIndex("1");
                      navigate(`/email-warmup/${value?.id}/report`);
                      setOpenDrawer(true);
                    },
                  },

                  {
                    key: "4",
                    label: (
                      <div className="dropdownText">
                        <p>Delete Email</p>
                      </div>
                    ),
                    icon: <img src={DeleteIcon} alt="setting" />,
                    onClick: () => {
                      setDeleteEmailModal(true);
                      console.log("value deleted", value);
                      setSelectedValue(value);
                    },
                  },
                ],
              }}
            >
              <span className="columData">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                    stroke="#98A2B3"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                    stroke="#98A2B3"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                    stroke="#98A2B3"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </Dropdown>
          </span>
        );
      },
    },
  ];

  // DEFINE FUNCTION FOR SETTING EMAIL API CALL
  const onWarmupSettingClose = (status) => {
    setWarmupModal(false);
    setOpenDrawer(false);
    setEditWarmupIndex("1");
    setSelectedValue({});
    if (status) {
      list(currentPage, pageSize, searchData, "", "", "", "", "", false);
    }
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {listLoading ? (
        <div className="no-data-component">
          <Spin />
        </div>
      ) : (
        <>
          {listOfEmail?.length > 0 || searchData !== undefined ? (
            <div className={"templateListTable"}>
              <Card>
                <Row
                  justify={"space-between"}
                  gutter={[20, 20]}
                  align={"middle"}
                  className="card-main-row"
                >
                  <div>
                    <Input
                      onChange={(event) => {
                        setSearchData(event.target.value);
                      }}
                      prefix={
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.58317 17.5003C13.9554 17.5003 17.4998 13.9559 17.4998 9.58366C17.4998 5.2114 13.9554 1.66699 9.58317 1.66699C5.21092 1.66699 1.6665 5.2114 1.6665 9.58366C1.6665 13.9559 5.21092 17.5003 9.58317 17.5003Z"
                            stroke="#98A2B3"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            opacity="0.4"
                            d="M18.3332 18.3337L16.6665 16.667"
                            stroke="#98A2B3"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      placeholder="Search"
                      value={searchData}
                    />
                  </div>
                  <div className="add-button-container">
                    <button
                      onClick={() => {
                        navigate("/new-email");
                      }}
                    >
                      <span>+ Add Email Account</span>
                    </button>
                  </div>
                </Row>
                <div ref={tableRef}>
                  {console.log(
                    "tableLoadingtableLoadingtableLoading",
                    tableLoading
                  )}
                  <Table
                    className="templateListTable"
                    columns={columns}
                    dataSource={listOfEmail}
                    loading={tableLoading}
                    scroll={{
                      x: "1150px",
                    }}
                    pagination={false}
                    onRow={(record, index) => ({
                      onClick: () => {
                        setEditWarmupIndex("1");
                        setOpenDrawer(true);
                        setWarmupModal(true);
                        navigate(`/email-warmup/${record?.id}/report`);
                        setSelectedRow(index);
                        setSettingModelClick(false);
                        setSelectedValue(record);
                      },
                    })}
                    rowClassName={(record, index) =>
                      index === selectedRow ? "selected-row" : ""
                    }
                  />
                </div>
              </Card>
              {countsOfEmail.totalCount > 9 ? (
                <div className={style.tablePagenation}>
                  <Pagination
                    total={countsOfEmail.totalCount}
                    showSizeChanger
                    pageSizeOptions={[10, 20, 50, 100]}
                    defaultPageSize={pageSize}
                    defaultCurrent={currentPage}
                    onChange={onPageChange}
                  />
                  <span className={style.table_counts}>{`${
                    currentPage * pageSize - (pageSize - 1)
                  }-${currentPage * pageSize} of ${
                    countsOfEmail.totalCount
                  }`}</span>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="mainContainer">
              <div>
                <img src={img} alt="imgae" />
                <h2>Add email account</h2>
                <p>
                  Build your email reputation with our email warm-up & never
                  land in spam again. <br />
                  Add your first email account to get started.
                </p>
                <button
                  size="large"
                  onClick={() => {
                    navigate("/new-email");
                  }}
                >
                  <span>Add Email Account</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {warmupModal ? (
        <WarmUpSetting
          warmupModel={warmupModal}
          value={selectedValue}
          settingModelClick={settingModelClick}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          clickEditWarmup={editWarmupIndex}
          setEditWarmupIndex={setEditWarmupIndex}
          // onCloseDrawer={onCloseDrawer}
          onClose={onWarmupSettingClose}
          analyticsData={detailsOfEmailTypeAnalyticsData}
          onChartTypeChange={onEditEmail}
          loading={activeModalLoading}
        />
      ) : (
        ""
      )}
      {WarmupConfirmModal && (
        <WarmupConfirm
          WarmupConfirmModal={WarmupConfirmModal}
          selectedValue={selectedValue}
          onClose={() => {
            setWarmupConfirModal(false);
          }}
          onSubmit={ActiveEmailWarmup}
          loading={activeModalLoading}
          type="warmup"
        />
      )}
      {activeEmailModal && (
        <ActiveEmailModal
          activeEmailModal={activeEmailModal}
          selectedValue={selectedValue}
          onClose={() => {
            setActiveEmailModal(false);
          }}
          onSubmit={ActiveEmailWarmup}
          loading={activeModalLoading}
          type="warmup"
        />
      )}
      {deleteEmailModal && (
        <DeleteEmailModal
          deleteEmailModal={deleteEmailModal}
          selectedValue={selectedValue}
          onClose={() => {
            setDeleteEmailModal(false);
            setSelectedValue({});
          }}
          onSubmit={DeleteEmailWarmup}
          loading={deleteModalLoading}
          type="warmup"
        />
      )}
      {disconnectEmailModal && (
        <DisconnectEmailModal
          disconnectEmailModal={disconnectEmailModal}
          selectedValue={selectedValue}
          onClose={() => {
            setDisconnectEmailModal(false);
            setSelectedValue({});
          }}
          onSubmit={disconnectEmailWarmup}
          loading={disconnectEmailModalLoading}
          type="warmup"
        />
      )}
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default Dashboard;
